module.exports = [{
      plugin: require('/Users/benbeard/Sites/unum_fund/dev/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#262262","theme_color":"#262262","display":"minimal-ui","icon":"src/images/unum_report_favicon.png"},
    },{
      plugin: require('/Users/benbeard/Sites/unum_fund/dev/node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"appElement":"#___gatsby","modalProps":{}},
    },{
      plugin: require('/Users/benbeard/Sites/unum_fund/dev/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-150652987-1","head":true},
    },{
      plugin: require('/Users/benbeard/Sites/unum_fund/dev/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
